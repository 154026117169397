import Globe from "./Globe";

const HeroSection = ({ toggleModal }) => {
  return (
    <div
      className="hero-section h-screen flex items-center justify-center relative"
      style={{ backgroundColor: "#FDF7E9" }}
    >
      <div className="container mx-auto px-4 text-center">
        <Globe />
      </div>
    </div>
  );
};

export default HeroSection;
