import React from "react";

const VentureList = ({ ventures = [] }) => {
  return (
    <section
      id="ventures"
      className="ventures-section bg-customGreen py-12 mt-1"
    >
      <div className="container mx-auto px-4">
        <h2 className="text-5xl text-white text-left mb-4">Ventures</h2>
        <p className="text-white text-lg mb-4 mt-10">
          Sal & Turq represents our inaugural project, situated in the Caribbean
          on the island of Roatan renowned for its scuba diving attractions.
          This venture offers a premium lodging experience tailored to scuba
          diving enthusiasts from Canada, the USA, and Europe. All within the
          privilege of having its own private beach.
        </p>
        <p className="text-white text-lg mb-10">
          Originally slated to open its flagship bar in New York City prior to
          the pandemic, negotiations were halted due to the global crisis.
          Consequently, the company shifted its focus to identifying a new
          location, ultimately selecting Lisbon as a prime destination for its
          favorable climate, proximity to a port for sourcing specialty
          ingredients, competitive labor costs, and high quality of life.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {ventures.map((venture, index) => (
            <div
              key={index}
              className="bg-white border border-white shadow-md hover:shadow-lg transition-shadow duration-200"
            >
              <a
                href={venture.website} // Link to the website
                target="_blank" // Opens in a new tab
                rel="noopener noreferrer" // Security best practice
                className="block transform transition-transform duration-200"
              >
                <div className="relative pb-[68.42%]">
                  <img
                    src={venture.image}
                    alt={venture.name}
                    className="absolute top-0 left-0 w-full h-full object-cover"
                  />
                </div>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-700">
                    {venture.name}
                  </h3>
                  <p className="mt-2 text-sm text-gray-600">
                    {venture.description}
                  </p>
                  <div className="mt-4 text-sm">
                    <span className="text-gray-700 font-bold">Location:</span>{" "}
                    {venture.location}
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default VentureList;
