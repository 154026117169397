import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import * as topojson from "topojson-client";

const Globe = () => {
  const svgRef = useRef(null);
  const [size, setSize] = useState({
    width: window.innerWidth * 0.8,
    height: window.innerWidth * 0.8,
  });

  // Function to update size based on window width
  const updateSize = () => {
    const width = window.innerWidth * 0.8; // Adjust the size based on the viewport width
    setSize({ width: width, height: width }); // Set height equal to width for a full-width globe
  };

  useEffect(() => {
    // Set initial size
    updateSize();
    // Add event listener for window resize
    window.addEventListener("resize", updateSize);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", `0 0 ${size.width} ${size.height}`) // Use viewBox for responsive scaling
      .attr("preserveAspectRatio", "xMidYMid meet") // Preserve aspect ratio and center the content
      .style("margin", "0 auto") // Center the SVG within its container
      .style("display", "block"); // Ensure the SVG takes the full width of the container

    const projection = d3
      .geoOrthographic()
      .scale(size.width / 2.2) // Adjusted scale for proper globe size
      .translate([size.width / 2, size.height / 2])
      .rotate([0, -30]);

    const path = d3.geoPath().projection(projection);

    // Add ocean color (darker blue)
    svg.selectAll("circle.ocean").remove(); // Clear any existing ocean background
    svg
      .append("circle")
      .attr("class", "ocean")
      .attr("cx", size.width / 2)
      .attr("cy", size.height / 2)
      .attr("r", size.width / 2.2) // Adjusted radius for proper globe size
      .attr("fill", "#1c3d5a"); // Darker ocean color

    d3.json("https://d3js.org/world-110m.v1.json")
      .then(function (world) {
        const land = topojson.feature(world, world.objects.land);

        svg.selectAll("path").remove(); // Clear previous paths
        svg
          .append("path")
          .datum(land)
          .attr("d", path)
          .attr("fill", "#69b3a2") // Land color
          .attr("stroke", "#000");

        const locations = [
          { name: "Roatán, Honduras", coordinates: [-86.529, 16.329] },
          { name: "Lisbon, Portugal", coordinates: [-9.139, 38.722] },
          { name: "New York, USA", coordinates: [-74.006, 40.7128] },
        ];

        // Clear previous markers and add new pin icons
        svg.selectAll(".location-marker").remove();

        svg
          .selectAll(".location-marker")
          .data(locations)
          .enter()
          .append("svg:image")
          .attr("class", "location-marker")
          .attr("xlink:href", "/pin-icon.svg") // Replace with the correct path to your pin icon
          .attr("width", 15) // Adjust the size of the pin icon
          .attr("height", 20)
          .attr("x", (d) => projection(d.coordinates)[0] - 10) // Center the pin horizontally
          .attr("y", (d) => projection(d.coordinates)[1] - 20); // Center the pin vertically

        // Add location names
        svg.selectAll("text").remove(); // Clear previous texts
        svg
          .selectAll("text")
          .data(locations)
          .enter()
          .append("text")
          .attr("x", (d) => {
            const x = projection(d.coordinates)[0];
            return x + (x > size.width / 2 ? -10 : 10); // Adjust position based on proximity to the edge
          })
          .attr("y", (d) => projection(d.coordinates)[1] + 4)
          .text((d) => d.name)
          .attr("font-size", "12px")
          .attr("fill", "white")
          .attr("font-family", "sans-serif")
          .attr("text-anchor", (d) =>
            projection(d.coordinates)[0] > size.width / 2 ? "end" : "start"
          ); // Adjust text alignment

        // Slower rotation speed
        d3.timer(function (elapsed) {
          projection.rotate([elapsed * 0.005, -30]); // Reduced speed from 0.02 to 0.005
          svg.selectAll("path").attr("d", path);
          svg
            .selectAll(".location-marker")
            .attr("x", (d) => projection(d.coordinates)[0] - 10)
            .attr("y", (d) => projection(d.coordinates)[1] - 20);
          svg
            .selectAll("text")
            .attr("x", (d) => {
              const x = projection(d.coordinates)[0];
              return x + (x > size.width / 2 ? -10 : 10);
            })
            .attr("y", (d) => projection(d.coordinates)[1] + 4)
            .attr("text-anchor", (d) =>
              projection(d.coordinates)[0] > size.width / 2 ? "end" : "start"
            );
        });
      })
      .catch((error) => console.error("Error loading world map data:", error)); // Debugging: Catch any data loading errors
  }, [size]);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        textAlign: "center",
      }}
    >
      <svg ref={svgRef}></svg>
      <img
        src="/seedbar.png" // Replace with the correct path to your logo image
        alt="SeedBar Logo"
        style={{
          position: "absolute",
          top: "55%", // Center vertically
          left: "50%", // Center horizontally
          transform: "translate(-50%, -50%)", // Adjust for centering
          width: "30%", // Adjust the size of the logo as needed
          opacity: 0.5, // Add some transparency so the logo doesn't overpower the text
          pointerEvents: "none", // Ensure the logo doesn't block interactions
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "50%", // Center vertically
          left: "50%", // Center horizontally
          transform: "translate(-50%, -50%)", // Adjust for centering
          fontSize: "6rem", // Larger font size for visibility
          color: "#FDF7E9",
          fontFamily: "sans-serif",
          pointerEvents: "none", // Ensure the text doesn't block interactions
        }}
      >
        SeedBar Hospitality Group
      </div>
    </div>
  );
};

export default Globe;
