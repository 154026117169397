import React from "react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className="bg-customGreen text-white py-8 px-4">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-start md:items-center">
        {/* Left Side: Links */}
        <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-16">
          {/* First Column */}
          <div className="flex flex-col space-y-4">
            <a href="/" className="hover:underline">
              Home
            </a>
            <a href="/about" className="hover:underline">
              About Seedbar
            </a>
            <a href="/ventures" className="hover:underline">
              Ventures
            </a>
            {/* <a href="/investors" className="hover:underline">
              Investors
            </a> */}
          </div>

          {/* Second Column */}
          <div className="flex flex-col space-y-4">
            <p className="font-bold">Get in Touch</p>
            <div className="flex space-x-3">
              <a href="https://www.airbnb.com/h/salandturq">
                <div className="w-4 text-white scale-125 hover:text-secondary hover:scale-150 ease-in-out duration-300">
                  <Icon icon={faLinkedin} />
                </div>
              </a>
              <a href="https://www.instagram.com/sanlandturq/">
                <div className="w-4 text-white scale-125 hover:text-secondary hover:scale-150 ease-in-out duration-300">
                  <Icon icon={faInstagram} />
                </div>
              </a>
            </div>
          </div>
        </div>

        {/* Right Side: Logo */}
        <div className="mt-0 md:mt-0">
          <img src="/seedbar.png" alt="Company Logo" className="w-32" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
