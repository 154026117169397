import React, { useState } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import About from "./components/About";
import Footer from "./components/Footer";
import ContactForm from "./components/ContactForm";
import VentureList from "./components/VentureList";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const ventures = [
    {
      name: "Sal and Turq",
      description:
        "An oceanfront camper lodge located on the pristine beaches, offering a serene getaway for nature lovers.",
      location: "Roatan, Honduras",
      image: "/sal_and_turq.png",
      website: "https://www.salandturq.com",
    },
    {
      name: "Arco Bar",
      description:
        "Chic Bar with a vibrant atmosphere and upscale service locate in the heart of lisbon.",
      location: "Lisbon, Portugal",
      image: "/lisbon_bar.png",
    },
    {
      name: "Future Project",
      description:
        "Currently looking for a bar & food project for January 2026. Markets of Interest: United States, Mexico and Brazil. Interested Investors please contact us.",
      location: "USA, Mexico, Brazil",
      image: "/future_project.jpg",
    },
  ];

  return (
    <>
      <div className="App h-screen flex flex-col overflow-hidden">
        <Navbar toggleModal={toggleModal} />
        <HeroSection toggleModal={toggleModal} className="flex-grow" />
      </div>
      <div>
        <VentureList ventures={ventures} />
      </div>
      <About />
      <Footer />
      <ContactForm isOpen={isModalOpen} toggleModal={toggleModal} />
    </>
  );
}

export default App;
