import React from "react";

const About = () => {
  return (
    <section
      id="about"
      className="about-section flex justify-center items-center h-full py-10"
      style={{ backgroundColor: "#FDF7E9" }}
    >
      <div className="container mx-auto px-4">
        <div className="about-content flex flex-col md:flex-col lg:flex-row justify-start items-center">
          <div className="narrative-container w-full md:w-3/4 lg:w-2/5 mb-8 md:mb-0">
            <div className="about-text text-center md:text-left">
              <h2 className="text-5xl text-black font-sans">About Us</h2>
              <br />
              <p>
                SeedBar Group is a private American hospitality group,
                specializing in the hospitality sector, with a focus on bar and
                lodging establishments. Currently on its second project in
                Europe, the company is looking for opportunities in the United
                States, Brazil and Mexico.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
