import React, { useState, useEffect } from "react";

const Navbar = ({ toggleModal }) => {
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    const isVisible =
      prevScrollPos > currentScrollPos || currentScrollPos === 0;

    setPrevScrollPos(currentScrollPos);
    setVisible(isVisible);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const handleNavItemClick = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
    }
    setIsDrawerOpen(false);
  };

  const handleHomeClick = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setIsDrawerOpen(false);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <nav
      className={`fixed top-0 left-0 right-0 flex items-center justify-center px-6 z-10 ${visible ? "opacity-100 transition-opacity duration-500" : "opacity-0 transition-opacity duration-500"} bg-customGreen py-2`}
    >
      <ul className="hidden md:flex border border-white">
        <li>
          <a
            href="#"
            className="text-white font-bold no-underline transition-colors duration-300 font-sans"
            onClick={(e) => handleHomeClick(e)}
          >
            <button className="px-12 py-1">Home</button>
          </a>
        </li>
        <li>
          <a
            href="#"
            className="text-white font-bold no-underline transition-colors duration-300 font-sans"
            onClick={(e) => handleNavItemClick(e, "ventures")}
          >
            <button className="px-12 py-1">Ventures</button>
          </a>
        </li>
        <li>
          <a
            href="#"
            className="text-white font-bold no-underline transition-colors duration-300 font-sans"
            onClick={(e) => handleNavItemClick(e, "about")}
          >
            <button className="px-12 py-1">About Us</button>
          </a>
        </li>
        <li>
          <button
            className="px-12 py-1 text-white font-bold no-underline transition-colors duration-300 font-sans"
            onClick={toggleModal}
          >
            Contact
          </button>
        </li>
      </ul>
      <button
        className="block md:hidden text-black focus:outline-none z-20 absolute top-0 right-0 m-4"
        onClick={toggleDrawer}
      >
        <svg
          className="w-10 h-10"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d={isDrawerOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
          ></path>
        </svg>
      </button>
      <div
        className={`fixed top-0 left-0 w-full h-full bg-black-800 bg-opacity-75 z-10 transform ${isDrawerOpen ? "translate-x-0" : "-translate-x-full"} transition-transform duration-300 md:hidden`}
      >
        <div className="flex flex-col items-center justify-center h-full">
          <a
            href="#"
            className="text-black text-2xl py-4 font-sans"
            onClick={(e) => handleNavItemClick(e, "home")}
          >
            Home
          </a>
          <a
            href="#"
            className="text-black text-2xl py-4 font-sans"
            onClick={(e) => handleNavItemClick(e, "ventures")}
          >
            Ventures
          </a>
          <a
            href="#"
            className="text-black text-2xl py-4 font-sans"
            onClick={(e) => handleNavItemClick(e, "about")}
          >
            About Us
          </a>
          <button
            className="text-black text-2xl py-4 font-sans"
            onClick={toggleModal}
          >
            Contact
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
